<template>
  <div style="height: 100%;">
    <v-row class="mt-16">
      <v-col cols="12" md="6" offset-md="3">
        <v-card>
          <v-card-title class="title">404 {{ $t('page_not_found') }}</v-card-title>
          <v-card-subtitle>
            <router-link to='/'>{{ $t('to_home') }}</router-link>
          </v-card-subtitle>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "notFound"
}
</script>